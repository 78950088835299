import { getUserPermissions, getCurrentUser } from '../routerServices';

const ClientProfile = () => import(/* webpackChunkName: "ClientProfile", webpackPrefetch: true */ '@/views/User/ClientProfile');
const ClientProfileAbout = () => import(/* webpackChunkName: "ClientProfile", webpackPrefetch: true */ '@/views/User/ClientProfile/About');

const ClientHome = () => import(/* webpackChunkName: "UserClientDashboard", webpackPrefetch: true */ '@/views/User/ClientDashboard');

const ClientDashboard = () => import(/* webpackChunkName: "ClientDashboard" */ '@/views/Dashboard/Client/index.vue');

const ClientOnboarding = () => import(/* webpackChunkName: "ClientOnboarding" */ '@/views/ClientOnboarding/index.vue');

const JobManager = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManager/Client');
const JobManagerPostedJobs = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManager/Client/PostedJobs');
const JobManagerOffersSent = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManager/Client/Offers');
const JobManagerClientReports = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManager/Client/Reports');

const JobManagerReports = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManagerReports/Client');
const JobManagerTotalSpending = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManagerReports/Client/TotalSpendings');
const JobManagerBonuses = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManagerReports/Client/Bonuses');
const JobManagerMilestones = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManagerReports/Client/Milestones');
const JobManagerScheduledPayment = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManagerReports/Client/ScheduledPayment');
const JobManagerTimesheets = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManagerReports/Client/Timesheets');
const JobManagerContractTimesheets = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManagerReports/Client/ContractTimesheet');

const JobManagerHourlyContracts = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManager/Client/Contracts/Hourly.vue');
const JobManagerFixedContracts = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManager/Client/Contracts/Fixed.vue');
const JobManagerClientTimeOff = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManager/Client/TimeOff');

const JobManagerTimeOff = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManagerTimeOff');
const JobManagerCalendar = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManagerTimeOff/Calendar');
const JobManagerTimeOffRequests = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManagerTimeOff/TimeOffRequests');
const JobManagerLeaveBalance = () => import(/* webpackChunkName: "ClientJobManager" */ '@/views/JobManagerTimeOff/LeaveBalance');

export default [
  {
    name: 'client-home',
    path: '/client-home',
    component: ClientHome,
    beforeEnter: (to, from, next) => {
      const permissions = getUserPermissions();

      if (!permissions.isEmployer) {
        return next({ name: 'freelancer-home.info' });
      }

      next();
    },
  },
  {
    name: 'client-profile',
    path: '/client-profile/:userId',
    component: ClientProfile,
    props: route => ({ edit: String(route.query.edit) === 'true' }),
    meta: { requiresAuth: false },
    children: [
      {
        name: 'client-profile.about',
        path: 'about',
        component: ClientProfileAbout,
      },
    ],
  },
  {
    name: 'client-dashboard',
    path: '/dashboard/client',
    component: ClientDashboard,
    beforeEnter: (to, from, next) => {
      const permissions = getUserPermissions();
      if (!permissions?.isEmployer) {
        return next({
          name: 'page-not-found',
        });
      }

      return next();
    },
  },
  {
    name: 'client-onboarding',
    path: '/onboarding/client',
    component: ClientOnboarding,
    props: route => ({
      step: route.params.step,
    }),
    beforeEnter: (to, from, next) => {
      const permissions = getUserPermissions();
      const user = getCurrentUser();

      const { onboarding } = user;

      if (!permissions?.isEmployer) {
        return next({
          name: 'access-denied',
        });
      }

      if (!onboarding || onboarding?.isCompleted) {
        return next({
          name: 'client-profile.about',
          params: { userId: user.username },
        });
      }

      return next();
    },
    meta: {
      hideHeader: true,
      hideFooter: true,
    },
  },
  {
    name: 'job-manager',
    path: '/job-manager',
    component: JobManager,
    beforeEnter: (to, from, next) => {
      const permissions = getUserPermissions();

      if (!permissions.isEmployer) {
        return next({
          name: 'freelancer-job-manager',
        });
      }

      return next();
    },
    children: [
      {
        name: 'job-manager.jobs-posted',
        path: 'jobs-posted',
        component: JobManagerPostedJobs,
      },
      {
        name: 'job-manager.offers-sent',
        path: 'offers-sent',
        component: JobManagerOffersSent,
      },
      {
        name: 'job-manager.hourly-contracts',
        path: 'hourly-contracts',
        component: JobManagerHourlyContracts,
      },
      {
        name: 'job-manager.fixed-contracts',
        path: 'fixed-contracts',
        component: JobManagerFixedContracts,
      },
      {
        name: 'job-manager.time-off',
        path: 'time-off',
        component: JobManagerClientTimeOff,
      },
      {
        name: 'job-manager.reports',
        path: 'reports',
        component: JobManagerClientReports,
      },
    ],
  },
  {
    name: 'job-manager-reports',
    path: '/job-manager/reports',
    component: JobManagerReports,
    beforeEnter: (to, from, next) => {
      const permissions = getUserPermissions();

      if (!permissions.isEmployer) {
        return next({
          name: 'access-denied',
        });
      }

      return next();
    },
    children: [
      {
        name: 'job-manager-reports.total-spendings',
        path: 'total-spendings',
        component: JobManagerTotalSpending,
      },
      {
        name: 'job-manager-reports.timesheets',
        path: 'timesheets',
        component: JobManagerTimesheets,
      },
      {
        name: 'job-manager-reports.milestones',
        path: 'milestones',
        component: JobManagerMilestones,
      },
      {
        name: 'job-manager-reports.scheduled-payments',
        path: 'scheduled-payments',
        component: JobManagerScheduledPayment,
      },
      {
        name: 'job-manager-reports.bonuses',
        path: 'bonuses',
        component: JobManagerBonuses,
      },
      {
        name: 'job-manager-reports.contract-timesheets',
        path: 'contract-timesheets/:contractId',
        component: JobManagerContractTimesheets,
      },
    ],
  },
  {
    name: 'job-manager-time-off',
    path: '/job-manager/time-off',
    component: JobManagerTimeOff,
    beforeEnter: (to, from, next) => {
      const permissions = getUserPermissions();

      if (!permissions.isEmployer) {
        return next({
          name: 'access-denied',
        });
      }

      return next();
    },
    children: [
      {
        name: 'job-manager-time-off.calendar',
        path: 'calendar',
        component: JobManagerCalendar,
      },
      {
        name: 'job-manager-time-off.time-off-requests',
        path: 'time-off-requests',
        component: JobManagerTimeOffRequests,
      },
      {
        name: 'job-manager-time-off.leave-balance',
        path: 'leave-balance',
        component: JobManagerLeaveBalance,
      },
    ],
  },
];
